import { toast } from 'react-toastify';
import { ChatToast } from 'shared/ui';

type useChatToastProps = {
  icon?: string;
  title: string;
  content: string;
  onClick: () => void;
};

export const useChatToast =
  () =>
  ({ icon, title, content, onClick }: useChatToastProps) =>
    toast(<ChatToast icon={icon} title={title} content={content} />, {
      onClick,
      containerId: 'chatToastContainer',
    });
