import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { api } from 'shared/api';

import { rootReducer } from './ducks';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const logger = createLogger({
  predicate: () => process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
  collapsed: true,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api },
      },
    }).concat(logger),
  devTools: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
});

export const persistor = persistStore(store);

const makeStore = () => {
  return { ...store, __persisitor: persistor };
};

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type AppDispatch = typeof store.dispatch;
