import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  api,
  BlockUserDto,
  BlockUserDtoBlockStatusEnum,
  ChatRoom,
  Consultation,
  ConsultationOffer,
  ConsultationRequest,
  Message,
} from 'shared/api';
import {
  DEFAULT_AVATAR,
  DEFAULT_REQUEST_CHAT_TOAST_ICON,
  DEFAULT_RESPONSE_CHAT_TOAST_ICON,
  pusherEvents,
} from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { makeMessageContent } from 'shared/helpers';
import { PusherService } from 'shared/lib';
import { actions, selectors } from 'store/ducks';

import { useAppDispatch } from './redux';
import { useChatToast } from './useChatToast';

const createPrivateChannel = (userId: number) => `private-user_${userId}`;

export const useUserPrivateCannel = () => {
  const { t } = useTranslation('utils');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const accessToken = useSelector(selectors.profile.selectToken);
  const user = useSelector(selectors.profile.selectUser);
  const chatToast = useChatToast();
  const currentChatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);
  const currentChatRoomRef = useRef<number | null>(null);
  const chatRooms = useSelector(selectors.chatRooms.selectChatRooms);

  useEffect(() => {
    currentChatRoomRef.current = currentChatRoomId;
  }, [currentChatRoomId]);

  useEffect(() => {
    if (accessToken) {
      const pusher = PusherService.init(accessToken);
      const privateUserCannel = user.id && createPrivateChannel(user.id);

      if (pusher && privateUserCannel) {
        const privateChannel = pusher.subscribe(privateUserCannel);

        // general events
        privateChannel.bind(pusherEvents.consultationRequests.created, (data: ConsultationRequest) => {
          if (user.id !== data.clientId) {
            if (user.categories?.findIndex((category) => category.categoryId === data.categoryId) !== -1) {
              dispatch(actions.consultationRequests.addNewClientRequest(data));

              chatToast({
                title: t('chatToast.newRequestTitle', { category: data.category.name }),
                content: data.content || '',
                icon: data.category.rootCategory?.iconUrl || DEFAULT_REQUEST_CHAT_TOAST_ICON,
                onClick: () =>
                  router.push({ pathname: routes.chat, query: { activeTab: 'requests', roomId: data.categoryId } }),
              });
            }
          }
        });

        privateChannel.bind(pusherEvents.consultationOffers.created, (data: ConsultationOffer) => {
          dispatch(actions.consultationRequests.addNewOfferToRequest(data));

          chatToast({
            title: t('chatToast.newResponseTitle', { category: data.consultationRequest.category.name }),
            content: t('chatToast.newResponseContent', { expert: data.expert.firstName, price: data.price }),
            icon: data.consultationRequest.category.rootCategory?.iconUrl || DEFAULT_RESPONSE_CHAT_TOAST_ICON,
            onClick: () => {
              router.push({
                pathname: routes.chat,
                query: { activeTab: 'responses', roomId: data.consultationRequestId },
              });
            },
          });
        });

        privateChannel.bind(pusherEvents.messages.received, (data: { message: Message }) => {
          dispatch(actions.chatRooms.updateLastMessage({ message: data.message, user }));

          if (user.id === data.message.authorId) {
            return;
          }

          if (data.message.chatRoomId === currentChatRoomRef.current) {
            return;
          }

          const chatRoom = chatRooms.find((chat: any) => chat.id === data.message.chatRoomId);
          if (!chatRoom) {
            return;
          }

          const isExpertInRoom = user.id === chatRoom.expertId;
          const isMutedChat = isExpertInRoom ? chatRoom.isExpertMuteChat : chatRoom.isClientMuteChat;

          if (!isMutedChat) {
            chatToast({
              title: t('chatToast.newMessageFrom', { author: data.message.author?.firstName }),
              content: makeMessageContent(data.message, t),
              icon: data.message.author?.avatarUrl || DEFAULT_AVATAR,
              onClick: () => {
                if (data.message.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.message.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.chatRoom.created, (data: { chat: ChatRoom }) => {
          dispatch(actions.chatRooms.addChatRoom(data.chat));
        });

        privateChannel.bind(pusherEvents.blocking.block, (data: BlockUserDto) => {
          // const alertTitle =
          //   data.blockStatus === BlockUserDtoBlockStatusEnum.Unblock ? t('block.unblockTitle') : t('block.blockTitle');
          const alertContent = () => {
            switch (data.blockStatus) {
              case BlockUserDtoBlockStatusEnum.Unblock:
                return t('block.unblockTitle');
              case BlockUserDtoBlockStatusEnum.Topic:
                return t('block.blockTopics', { reason: data.reasonBlock });
              case BlockUserDtoBlockStatusEnum.Partial:
                return `${t('block.blockTitle')} ${t('block.blockPartial', { reason: data.reasonBlock })}`;
              case BlockUserDtoBlockStatusEnum.Full:
                return `${t('block.blockTitle')} ${t('block.blockFull', { reason: data.reasonBlock })}`;
              default:
                return '';
            }
          };
          toast.warning(alertContent());
          if (data.blockStatus === BlockUserDtoBlockStatusEnum.Full) {
            dispatch(actions.profile.signOut());
          } else {
            dispatch(actions.profile.fetchMe());
          }
        });

        // extra services
        privateChannel.bind(pusherEvents.extraServices.created, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.createExtraService(data));
          }
        });

        privateChannel.bind(pusherEvents.extraServices.paid, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.paidExtraService(data));
          }
        });

        privateChannel.bind(pusherEvents.extraServices.rejected, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.rejectExtraService(data));
          }
        });

        // companion writing message
        privateChannel.bind(pusherEvents.chatRoom.companionWritingStart, (data: { chatRoomId: number }) => {
          dispatch(actions.chatRooms.setCompanionWritten({ chatRoomId: data.chatRoomId, isWritten: true }));
        });

        privateChannel.bind(pusherEvents.chatRoom.companionWritingEnd, (data: { chatRoomId: number }) => {
          dispatch(actions.chatRooms.setCompanionWritten({ chatRoomId: data.chatRoomId, isWritten: false }));
        });

        // interval for user-private-channel
        const updateLastExitTime = () => {
          api.V1UsersApi.usersControllerUpdateLastExitTime({ lastExitTime: dayjs().format() });
        };
        const intervalId = setInterval(() => {
          updateLastExitTime();
        }, 300000);
        updateLastExitTime();

        // cleanup
        return () => {
          privateChannel.unbind();
          pusher.unsubscribe(privateUserCannel);
          api.V1UsersApi.usersControllerUpdateLastExitTime(
            { lastExitTime: dayjs().format() },
            { headers: { Authorization: `Bearer ${accessToken}` } },
          );
          clearInterval(intervalId);
        };
      }
    }
  }, [accessToken, user]);
};
