import Pusher, { PresenceChannel } from 'pusher-js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { User } from 'shared/api';
import { actions, selectors } from 'store/ducks';

import { useAppDispatch } from './redux';

type Member = {
  id: string;
  info: User;
};

const PRESENCE_CHANNEL = 'presence-consulty';

export const usePresenceChannel = () => {
  const dispatch = useAppDispatch();
  const accessToken = useSelector(selectors.profile.selectToken);

  useEffect(() => {
    if (accessToken) {
      // const pusher = pusherService.init(accessToken);
      const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY!, {
        cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
        forceTLS: false,
        authEndpoint: process.env.NEXT_PUBLIC_PUSHER_AUTH_ENDPOINT,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });

      if (pusher) {
        const presenceChannel = pusher.subscribe(PRESENCE_CHANNEL) as PresenceChannel;

        presenceChannel.bind('pusher:subscription_succeeded', () => {
          const membersArray = Object.keys(presenceChannel.members.members).map((id) => Number(id));
          dispatch(actions.chatRooms.getOnlineMembers(membersArray));
        });

        presenceChannel.bind('pusher:member_added', (member: Member) => {
          dispatch(actions.chatRooms.addMember(Number(member.id)));
        });

        presenceChannel.bind('pusher:member_removed', (member: Member) => {
          dispatch(actions.chatRooms.removeMember(Number(member.id)));
        });

        return () => {
          presenceChannel.unbind();
          pusher.unsubscribe(PRESENCE_CHANNEL);
        };
      }
    }
  }, [accessToken]);
};
