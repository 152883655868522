import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ChatConsultationOffer, Consultation, ConsultationStatusEnum } from 'shared/api';
import {
  DEFAULT_AVATAR,
  DEFAULT_REQUEST_CHAT_TOAST_ICON,
  DEFAULT_RESPONSE_CHAT_TOAST_ICON,
  DEFAULT_THINK_CHAT_TOAST_ICON,
  pusherEvents,
} from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { PusherService } from 'shared/lib';
import { actions, selectors } from 'store/ducks';

import { useAppDispatch } from './redux';
import { useChatToast } from './useChatToast';

const createPrivateChannel = (userId: number) => `private-user_${userId}`;

export const useUserPrivateConsultationsCannel = () => {
  const { t } = useTranslation('utils');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const accessToken = useSelector(selectors.profile.selectToken);
  const user = useSelector(selectors.profile.selectUser);
  const chatToast = useChatToast();
  const currentChatRoomId = useSelector(selectors.chatRooms.selectCurrentChatRoomId);
  const currentChatRoomRef = useRef<number | null>(null);

  useEffect(() => {
    currentChatRoomRef.current = currentChatRoomId;
  }, [currentChatRoomId]);

  useEffect(() => {
    if (accessToken) {
      const pusher = PusherService.init(accessToken);
      const privateUserCannel = user.id && createPrivateChannel(user.id);

      if (pusher && privateUserCannel) {
        const privateChannel = pusher.subscribe(privateUserCannel);

        // consultations events
        privateChannel.bind(pusherEvents.chatConsultationOffer.created, async (data: ChatConsultationOffer) => {
          await dispatch(actions.chatRooms.addChatRoomIfNotExist(data.consultation));
          dispatch(
            actions.chatRooms.changeConsultationStatus({
              roomId: data.chatRoomId,
              consultationId: data.consultationId,
              status: data.consultation.status,
            }),
          );
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(
              actions.consultationsChatRoom.changeConsultationByIdOrAdd({
                ...data.consultation,
                consultationOffer: data,
              }),
            );
          }
          chatToast({
            title: t('chatToast.consultations.pendingPayment.title'),
            content: t('chatToast.consultations.pendingPayment.content', { name: data.author?.firstName }),
            icon: data.author?.avatarUrl || DEFAULT_AVATAR,
            onClick: () => {
              if (data.chatRoomId !== currentChatRoomRef.current) {
                router.push({
                  pathname: routes.chat,
                  query: { activeTab: 'consult', roomId: data.chatRoomId },
                });
              }
            },
          });
        });

        privateChannel.bind(pusherEvents.chatConsultationOffer.rejected, async (data: ChatConsultationOffer) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          dispatch(
            actions.chatRooms.changeConsultationStatus({
              roomId: data.chatRoomId,
              consultationId: data.consultationId,
              status: ConsultationStatusEnum.ClientRejectChatOffer,
            }),
          );
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.rejectConsultationOffer(data));
          }
          if (user.id === data.authorId) {
            chatToast({
              title: t('chatToast.consultations.rejectConsultationOffer.title'),
              content: t('chatToast.consultations.rejectConsultationOffer.content'),
              icon: DEFAULT_THINK_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.pendingStart, async (data: Consultation) => {
          await dispatch(actions.chatRooms.addChatRoomIfNotExist(data));
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          dispatch(
            actions.chatRooms.changeConsultationStatus({
              roomId: data.chatRoomId,
              consultationId: data.id,
              status: data.status,
            }),
          );
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.changeConsultationByIdOrAdd(data));
          }
          const isExpertInRoom = user.id === data.expertId;
          chatToast({
            title: isExpertInRoom
              ? t('chatToast.consultations.pendingStart.title.expert', { name: data.client.firstName })
              : t('chatToast.consultations.pendingStart.title.client'),
            content: isExpertInRoom
              ? t('chatToast.consultations.pendingStart.content.expert')
              : t('chatToast.consultations.pendingStart.content.client'),
            icon: isExpertInRoom ? data.client.avatarUrl || DEFAULT_AVATAR : data.expert.avatarUrl || DEFAULT_AVATAR,
            onClick: () => {
              if (data.chatRoomId !== currentChatRoomRef.current) {
                router.push({
                  pathname: routes.chat,
                  query: { activeTab: 'consult', roomId: data.chatRoomId },
                });
              }
            },
          });
        });

        privateChannel.bind(pusherEvents.consultation.paymentExpired, (data: Consultation) => {
          dispatch(
            actions.chatRooms.changeConsultationStatus({
              roomId: data.chatRoomId,
              consultationId: data.id,
              status: ConsultationStatusEnum.PaymentExpired,
            }),
          );
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(
              actions.consultationsChatRoom.updateConsultationsStatus({
                ...data,
                status: ConsultationStatusEnum.PaymentExpired,
              }),
            );
          }
        });

        privateChannel.bind(pusherEvents.consultation.starts, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          dispatch(
            actions.chatRooms.changeConsultationStatus({
              roomId: data.chatRoomId,
              consultationId: data.id,
              status: data.status,
            }),
          );
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.updateConsultationsStatus(data));
          }

          if (user.id === data.clientId && data.chatRoomId !== currentChatRoomRef.current) {
            chatToast({
              title: t('chatToast.consultations.start.title'),
              content: t('chatToast.consultations.start.content', {
                name: data.expert.firstName,
                theme: data.category?.name,
              }),
              icon: data.expert.avatarUrl || DEFAULT_AVATAR,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.timeExceeded, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.updateConsultationByTimeExceeded(data));
            dispatch(actions.consultationsChatRoom.setIsReviewModalOpen(true));
          } else {
            chatToast({
              title: t('chatToast.consultations.end.title'),
              content: t('chatToast.consultations.end.endTime'),
              icon: DEFAULT_REQUEST_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.messagesLimitExceeded, (data: Consultation) => {
          dispatch(actions.consultationsChatRoom.setIsReviewModalOpen(true));

          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.updateConsultationsStatus(data));
          } else {
            chatToast({
              title: t('chatToast.consultations.end.title'),
              content: t('chatToast.consultations.end.endMessageLimit'),
              icon: DEFAULT_REQUEST_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.endedByClient, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.updateConsultationsStatus(data));
            dispatch(actions.consultationsChatRoom.setIsReviewModalOpen(true));
          } else {
            chatToast({
              title: t('chatToast.consultations.end.title'),
              content: t('chatToast.consultations.end.endByClient'),
              icon: DEFAULT_REQUEST_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.endedByExpert, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.updateConsultationsStatus(data));
            dispatch(actions.consultationsChatRoom.setIsReviewModalOpen(true));
          } else {
            chatToast({
              title: t('chatToast.consultations.end.title'),
              content: t('chatToast.consultations.end.endByExpert'),
              icon: DEFAULT_REQUEST_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.prolongationRequest, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.addProlongationRequestToConsultation(data));
          } else if (user.id === data.clientId) {
            chatToast({
              title: t('chatToast.consultations.pendingProlongation.title'),
              content: t('chatToast.consultations.pendingProlongation.content'),
              icon: DEFAULT_RESPONSE_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.prolonged, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.prolongationConsultation(data));
          } else if (user.id === data.expertId) {
            chatToast({
              title: t('chatToast.consultations.prolonged.title'),
              content: t('chatToast.consultations.prolonged.content'),
              icon: DEFAULT_REQUEST_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.consultation.prolongationRequestDeclined, (data: Consultation) => {
          dispatch(actions.chatRooms.addUnreadMark({ roomId: data.chatRoomId }));
          if (data.chatRoomId === currentChatRoomRef.current) {
            dispatch(actions.consultationsChatRoom.prolongationDecline(data));
          } else if (user.id === data.expertId) {
            chatToast({
              title: t('chatToast.consultations.rejectProlongation.title'),
              content: t('chatToast.consultations.rejectProlongation.content'),
              icon: DEFAULT_THINK_CHAT_TOAST_ICON,
              onClick: () => {
                if (data.chatRoomId !== currentChatRoomRef.current) {
                  router.push({
                    pathname: routes.chat,
                    query: { activeTab: 'consult', roomId: data.chatRoomId },
                  });
                }
              },
            });
          }
        });

        privateChannel.bind(pusherEvents.zoom.zoomConsultationPaid, (data: Consultation) => {
          console.log('data', data);
          dispatch(actions.bookingZoom.setLink(data.linkForZoom ?? ''));
        });

        privateChannel.bind(pusherEvents.zoom.zoomConsultationDeclined, () => {
          dispatch(actions.bookingZoom.setError(true));
        });

        // cleanup
        return () => {
          privateChannel.unbind();
          pusher.unsubscribe(privateUserCannel);
        };
      }
    }
  }, [accessToken, user]);
};
