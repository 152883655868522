// eslint-disable-next-line simple-import-sort/imports
import 'app/styles/globals.scss';

import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import { useAppDispatch } from 'hooks/redux';
import { usePresenceChannel } from 'hooks/usePresenceChannel';
import { useUserPrivateCannel } from 'hooks/useUserPrivateChannel';
import { useUserPrivateConsultationsCannel } from 'hooks/useUserPrivateConsultationsChannel';
import type { AppContext, AppProps } from 'next/app';
import NextApp from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import NProgress from 'nprogress';
import React, { useEffect, useRef } from 'react';
import GoogleAnalytics from 'react-ga';
import { Provider, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import { YMInitializer } from 'react-yandex-metrika';
import { AxiosInterceptors } from 'shared/api/httpClient';
import { AUDIO_MESSAGE_EXT, COOKIE_KEY, ENVIRONMENT_TYPE, INTERCOM_APP_ID } from 'shared/common/constants';
import { getCookie } from 'shared/helpers';
import { actions, selectors } from 'store/ducks';
import { createGlobalStyle } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '../store';

Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeError', () => NProgress.done());

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const GlobalStyle = createGlobalStyle`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .react-images__positioner {
    transition: none !important;
  }
`;

AxiosInterceptors.setup(store);

function Main(props: AppProps) {
  const renderMetrics = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      return (
        <>
          <YMInitializer accounts={[66496642]} options={{ webvisor: true }} version="2" />
          {GoogleAnalytics.initialize('UA-177534398-1')}
        </>
      );
    }
  };

  return (
    <Provider store={store}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <PersistGate loading={null} persistor={persistor}>
          <App {...props} />
          {renderMetrics()}
        </PersistGate>
      </IntercomProvider>
    </Provider>
  );
}

const App = ({ Component, pageProps: { token, ...props } }: AppProps) => {
  const dispatch = useAppDispatch();
  const tokenFromStore = useSelector(selectors.profile.selectToken);
  const user = useSelector(selectors.profile.selectUser);
  const oldToken = useRef<string | null>(null);
  useUserPrivateCannel();
  useUserPrivateConsultationsCannel();
  usePresenceChannel();

  useEffect(() => {
    oldToken.current = tokenFromStore;
  }, [tokenFromStore]);

  useEffect(() => {
    if (token && oldToken.current !== token) {
      dispatch(actions.profile.setToken(token));
      dispatch(actions.profile.fetchMe());
      dispatch(actions.profile.fetchWallet());
      dispatch(actions.profile.fetchSelfPromocode());
      dispatch(actions.profile.fetchConsultationsWithoutReview());
      dispatch(actions.profile.fetchConsultations({ page: 1, filter: ['asClient', 'PAID'] }));
    }
  }, [token]);

  useEffect(() => {
    if (!user.id) {
      return;
    }
    if (user.isExpert) {
      dispatch(actions.services.getManyExpertsServicesAsync());
    }

    if (user.isExpert && user.categories?.length) {
      dispatch(
        actions.consultationRequests.fetchManyClientsRequests({
          filter: [
            `categoryId||$in||${user.categories?.map((c) => c.categoryId).join(',')}`,
            `clientId||$ne||${user.id}`,
            `status||$in||active`,
          ],
        }),
      );
    } else {
      dispatch(actions.consultationRequests.clearClientRequests());
    }
    dispatch(actions.profile.fetchContentChangeRequests());
    dispatch(actions.chatRooms.getChatRooms({ user }));
    dispatch(
      actions.consultationRequests.fetchManyConsultationsRequests({
        filter: [`clientId||$eq||${user.id}`, `status||$eq||active`],
      }),
    );
  }, [user.id]);

  useEffect(() => {
    import('audio-recorder-polyfill')
      .then((audioRecorder) => {
        audioRecorder.default.encoder = mpegEncoder;
        audioRecorder.default.prototype.mimeType = `audio/${AUDIO_MESSAGE_EXT}`;
        window.MediaRecorder = audioRecorder.default;
      })
      .catch((e) => {
        throw new Error(e);
      });
  }, []);

  //   const appendFbPixelScript = () => {
  //     if (ENVIRONMENT_TYPE === 'production') {
  //       return (
  //         <script
  //           dangerouslySetInnerHTML={{
  //             __html: `<!-- Facebook Pixel Code -->
  // !function(f,b,e,v,n,t,s)
  // {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  // n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  // if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  // n.queue=[];t=b.createElement(e);t.async=!0;
  // t.src=v;s=b.getElementsByTagName(e)[0];
  // s.parentNode.insertBefore(t,s)}(window, document,'script',
  // 'https://connect.facebook.net/en_US/fbevents.js');
  // fbq('init', '470297194334893');
  // fbq('track', 'PageView')
  // <!-- End Facebook Pixel Code -->`,
  //           }}
  //         />
  //       );
  //     }
  //   };

  const appendGaScript = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      return (
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || []; 
                function gtag(){dataLayer.push(arguments)}
                gtag('js', new Date()); 
                gtag('config', 'UA-177534398-1');`,
          }}
        />
      );
    }
  };

  const appendGoogleTagManagerScript = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      return (
        <script
          dangerouslySetInnerHTML={{
            __html: `
           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5QTB7C5');
          `,
          }}
        />
      );
    }
  };

  const appendVkPixelScript = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      return (
        <>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line max-len
              __html: `!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("VK-RTRG-1382455-5OSx6"),VK.Retargeting.Hit()},document.head.appendChild(t)}();`,
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line max-len
              __html: `<img src='https://vk.com/rtrg?p=VK-RTRG-1382455-5OSx6' style='position:fixed; left:-999px;' alt=''/>`,
            }}
          />
        </>
      );
    }
  };

  return (
    <>
      <GlobalStyle />
      <Head>
        <link rel="stylesheet" type="text/css" href="/nprogress.css" />
        <meta name="facebook-domain-verification" content="harlq5tch0u63fvj57kmyenzjs7evd" />
        {/* {appendFbPixelScript()} */}
        {appendGaScript()}
        {appendGoogleTagManagerScript()}
        {appendVkPixelScript()}
      </Head>
      <Component {...props} />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        closeButton={false}
        limit={1}
        enableMultiContainer
      />
      <ToastContainer
        containerId="chatToastContainer"
        enableMultiContainer
        toastClassName="chat-toast-container"
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        pauseOnHover
        limit={5}
      />
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5QTB7C5"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  );
};
Main.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const appProps = await NextApp.getInitialProps(appContext);
  let accessToken;

  if (ctx.req) {
    accessToken = getCookie(COOKIE_KEY.accessToken, ctx.req);
  }

  return { ...appProps, pageProps: { ...appProps.pageProps, token: accessToken || '' } };
};

export default appWithTranslation(Main);
